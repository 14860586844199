

























































import { Vue, Component, Prop,  Watch } from 'vue-property-decorator'
import { Fragment } from 'vue-fragment'
import {
	AtributoDoProduto,
	Variacao,
	Variante,
	Produto,
	Atributo,	
	FormProduto,
} from '@/models'
import type { FormProdutoSimplesComVariantes } from '@/models'
import CamposDeAtributoDoProduto from './CamposDeAtributoDoProduto.vue'
import cartesianProduct from 'cartesian-product'
import { FindAtributoUseCase } from '@/usecases/produto/FindAtributoUseCase'
import { displayVariantes, mesclarGradesDeAtributosVariantes } from '@/shareds/produto-shareds'
import PanelDeEdicaoDaVariante from './PanelDeEdicaoDaVariante.vue'

@Component({
	components: {
		Fragment,
		CamposDeAtributoDoProduto,
		PanelDeEdicaoDaVariante,
	},
})
export default class CamposDeProdutoComVariantes extends Vue {
	@Prop() value!: FormProdutoSimplesComVariantes
	@Prop({ type: Boolean, default: false }) skuAutomatico!: boolean
	opcoesDeAtributos: Atributo[] = []
	
	expandido = false

	findOpcaoDeAtributo = new FindAtributoUseCase()
	displayVariantes = displayVariantes

	set produto(produto: CamposDeProdutoComVariantes['value']) {
		this.$emit('input', produto)
	}

	get produto() {
		return this.value
	}

	atualizarVariante(indice: number, valor: any) {
		this.value.variantes.splice(indice, 1, valor)
	}

	async created() {
		this.opcoesDeAtributos = await this.findOpcaoDeAtributo.findAll()
	}
	get atributosSelecionadas() {
		return this.produto.atributosProduto.map(({ atributo }) => atributo)
	}

	adicionarAtributo() {
		this.produto.atributosProduto = [
			...this.produto.atributosProduto,
			{
				id: '',
				atributo: null,
				valores: [],
				descontoVarejo: 0,
			},
		]
	}

	excluirVariante(indice: number) {
		this.produto.variantes.splice(indice, 1)
		
	}

	excluirAtributo(indice: number) {
		this.produto.atributosProduto.splice(indice, 1)
	}

	get gradeTotal() {
		return this.criarVariantesDoProduto(
			this.produto as Produto,
			this.produto.atributosProduto as AtributoDoProduto[],
		)
	}

	criarVariantesDoProduto(
		produto: Produto,
		atributos: AtributoDoProduto[],
	) {
		const atributosDaVariante = atributos
			.filter(({ valores }) => valores.length)
			.map(({ id, atributo, valores }) =>
				valores.map(valor => ({
					id,
					nome: atributo.nome,
					valor,
				})),
			)
		const variacoes: Variacao[] = cartesianProduct(atributosDaVariante)
		const variantes = variacoes.map<FormProduto>(variacao => ({
			id: '',
			ncm: null,
			cest: null,
			regraFiscal: null,
			idProdutoPai: produto.id,
			tipo: 'Variante',
			tags: produto.tags,
			descricao: null,
			categoria: produto.categoria,
			urlImagens: produto.urlImagens,
			sku: null,
			preco: null,
			pesoLiquido: produto.pesoLiquido,
			pesoBruto: produto.pesoBruto,
			descontoVarejo: null,
			marca: produto.marca,
			nome: produto.nome,
			atributosVariante: variacao,
			temGarantia: produto.temGarantia,
			regrasFiscais: [],
			eans: [],
			unidadeDeMedida: produto.unidadeDeMedida,
			identificadores: [],
			produtoDesativado: produto.produtoDesativado,
			produtoIntegrado: produto.produtoIntegrado,
			genero: produto.genero,
			fatorDeConversao: produto.fatorDeConversao,
			unidadeDeConversao: produto.unidadeDeConversao,
			estacao: produto.estacao,
			isValePresente: false,
			skuPai: produto.skuPai,
			quantidadeImportacao: null,
			fornecedor: produto.fornecedor,
			precoCompra: null,
			margemLucro: null,
		}))
		return variantes
	}

	@Watch('gradeTotal')
	onChangeGradeTotal(gradeTotal: CamposDeProdutoComVariantes['gradeTotal']) {
		this.produto.variantes = JSON.parse(JSON.stringify(mesclarGradesDeAtributosVariantes(this.produto.variantes as Variante[] , gradeTotal as Variante[])))
	}

	formarNomeDaVariacao(variacao: Variacao) {
		return variacao.map(atributo => atributo.valor).join(' / ')
	}

	async deletaVariante(idVariante: string | undefined) {
		if (!idVariante) return
		this.produto.variantes = this.produto.variantes.filter(
			variante => variante.id !== idVariante,
		)
	}
}
